import { request, noTimeOutReq } from '@/utils/request.js'

//查询最新考试
export function getCompanyNewExamApi () {
  return request({
    url: `/company/join/exam/data/query/new/list`,
    method: 'post',
  })
}

//获取考试列表
export function getCompanyExamListApi (pageIndex, pageSize, data) {
  return request({
    url: `/company/join/exam/info/list/${pageIndex}/${pageSize}`,
    method: 'post',
    // headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}

//删除考试
export function removeCompanyExamApi (companyCityExamId) {
  return request({
    url: `/company/join/exam/info/delete/${companyCityExamId}`,
    method: 'post',
  })
}


//导入市级考试数据Excel
export function importExcel (data) {
  return noTimeOutReq({
    url: `/company/join/exam/info/import/excel`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}