<template>
  <div id="examManage">
    <!-- 导入组件 -->
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
    <div>
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="getCompanyNewExam"
        >查询最新考试</el-button
      >
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        >导入考试</el-button
      >
      <!-- <el-button v-throttle  type="danger" size="medium" @click="batchRemove">批量删除</el-button> -->
    </div>

    <div style="margin-top: 20px">
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examName"
            placeholder="请输入考试名称"
            @input="reloadCompanyExamList"
          ></el-input>
        </el-form-item>

        <el-form-item label="考试编码" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examCode"
            placeholder="请输入考试编码"
            @input="reloadCompanyExamList"
          ></el-input>
        </el-form-item>

        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            class="placeholderColor"
            v-model="queryParam.subjectType"
            placeholder="全部"
            @change="reloadCompanyExamList"
          >
            <el-option :value="0">全部</el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            class="placeholderColor"
            v-model="queryParam.examStatus"
            placeholder="全部"
            @change="reloadCompanyExamList"
          >
            <el-option value>全部</el-option>
            <el-option
              v-for="item in examStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试数据" class="formItemBoxStyle">
          <el-select
            class="placeholderColor"
            v-model="queryParam.examDataStatus"
            placeholder="全部"
            @change="reloadCompanyExamList"
          >
            <el-option value>全部</el-option>
            <el-option
              v-for="item in examDataStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考后数据" class="formItemBoxStyle">
          <el-select
            class="placeholderColor"
            v-model="queryParam.examResultStatus"
            placeholder="全部"
            @change="reloadCompanyExamList"
          >
            <el-option value>全部</el-option>
            <el-option
              v-for="item in examResultStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanyExamList"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <div>
        <el-table
          v-loading="loading"
          stripe
          border
          :data="companyExamList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', background: '#DFE6EC' }"
          :cell-style="{ 'text-align': 'center' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="examName"
            label="考试名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="examCode"
            label="考试编码"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="subjectTypeList"
            label="考试科目"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.subjectTypeList | subjectTypeFilter
            }}</template>
          </el-table-column>
          <el-table-column
            prop="examStatus"
            label="考试状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.examStatus == 2">等待考试</span>
              <span v-else-if="scope.row.examStatus == 3">正在考试</span>
              <span v-else-if="scope.row.examStatus == 4">考试完成</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="examDataStatus"
            label="考试数据"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.examDataStatus == 1">未获取</span>
              <span v-else-if="scope.row.examDataStatus == 2">已获取</span>
              <span v-else-if="scope.row.examDataStatus == 3">已确认</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="examResultStatus"
            label="考后数据"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.examResultStatus == 1">未上传</span>
              <span v-else-if="scope.row.examResultStatus == 2">已上传</span>
              <span v-else-if="scope.row.examResultStatus == 3">已确认</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="110px" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button
                v-throttle
                size="mini"
                type="primary"
                @click="examInfo(scope.row.examId, scope.row.companyCityExamId)"
                >考试信息</el-button
              >
              <el-button
                v-throttle
                size="mini"
                type="primary"
                @click="afterExamUp(scope.row)"
                :disabled="scope.row.examId == null"
                >考后上传</el-button
              >
              <el-button
                v-throttle
                size="mini"
                type="danger"
                @click="removeCompanyExam(scope.row.companyCityExamId)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParam.pageIndex"
          :page-size="pageParam.pageSize"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParam.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanyNewExamApi,
  getCompanyExamListApi,
  removeCompanyExamApi,
  importExcel,
} from '@/api/schoolManufacturer/examManage.js'
import { isEmpty } from '@/utils/util.js'
import { setStore } from '@/utils/token.js'
export default {
  name: 'examManage',
  data() {
    return {
      queryParam: {
        examName: '',
        examCode: '',
        subjectType: 0,
        examStatus: 0,
        examDataStatus: 0,
        examResultStatus: 0,
      },
      loading: false,
      companyExamList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      subjectOptions: [],
      examStatusOptions: [],
      examDataStatusOptions: [],
      examResultStatusOptions: [],
      //已勾选列
      selectedList: [],
      idList: [],
      // 导入所需
      uploadMsg: '',
      uploadLoading: false,
    }
  },
  mounted() {
    this.renderSelect()
    this.getCompanyExamList()
  },
  methods: {
    //渲染下拉框数据
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions
      this.examStatusOptions = this.$Global.globalExamStatusOptions
      this.examDataStatusOptions = this.$Global.globalExamDataStatusOptions
      this.examResultStatusOptions = this.$Global.globalMarkResultStatusOptions
    },
    //查询最新考试
    getCompanyNewExam() {
      const loading = this.globalLoading('正在查询最新考试...')
      getCompanyNewExamApi()
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('获取成功')
            this.getCompanyExamList()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //获取考试列表
    getCompanyExamList() {
      this.loading = true
      let queryParam = this.queryParam
      for (let key in queryParam) {
        if (
          queryParam[key] == 0 ||
          queryParam[key] == '' ||
          queryParam[key] == undefined
        ) {
          delete queryParam[key]
        }
      }
      getCompanyExamListApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.companyExamList = []
            this.companyExamList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //刷新
    refresh() {
      this.resetQueryParam()
    },
    //清空查询条件
    resetQueryParam() {
      this.queryParam.examName = ''
      this.queryParam.examCode = ''
      this.queryParam.subjectType = 0
      this.queryParam.examStatus = 0
      this.queryParam.examDataStatus = 0
      this.queryParam.examResultStatus = 0
      this.reloadCompanyExamList()
    },
    reloadCompanyExamList() {
      this.pageParam.pageIndex = 1
      this.getCompanyExamList()
    },
    //删除考试
    removeCompanyExam(val) {
      console.log('删除考试======================================', val)
      removeCompanyExamApi(val).then((res) => {
        if (res.success) {
          this.msgSuccess('已删除')
          this.getCompanyExamList()
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    //批量删除
    batchRemove() {
      if (this.selectedList.length <= 0) {
        this.msgWarning('请勾选要删除的考试')
        return
      }
      this.idList = []
      this.selectedList.forEach((item) => {
        if (!isEmpty(item)) {
          if (!isEmpty(item.examId)) {
            this.idList.push(item.examId)
          }
        }
      })
    },
    handleSelectionChange(val) {
      this.selectedList = []
      this.selectedList = val
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanyExamList()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanyExamList()
    },
    //查看考试信息
    examInfo(examId, companyCityExamId) {
      this.$router.push({
        path: '/schoolManufacturer/examInfo',
        query: {
          examId: examId,
          companyCityExamId: companyCityExamId,
        },
      })
    },
    //考后上传
    afterExamUp(val) {
      setStore('sysOrgExamPlaceIdList', val.sysOrgExamPlaceIdList)
      this.$router.push({
        path: '/schoolManufacturer/afterExamination',
        query: {
          examId: val.examId,
        },
      })
    },
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypeList':
          if (row.subjectTypeList.length <= 0) {
            return '未知'
          } else {
            let appendReturn = ''
            row.subjectTypeList.forEach((item, index) => {
              appendReturn += item.name
              if (index < row.subjectTypeList.length - 1) {
                appendReturn += ','
              }
            })
            return appendReturn
          }
      }
    },
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      importExcel(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            // this.$message.success('导入成功');
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.renderSelect()
            this.getCompanyExamList()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
  },
  filters: {
    subjectTypeFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const subjectMap = {
        1: '物理',
        2: '生物',
        3: '化学',
      }
      return val.map((item) => subjectMap[item] || `错误的值${item}`).join(',')
    },
  },
}
</script>

<style lang="scss" scoped>
#examManage ::v-deep .el-form .el-form-item__content .el-input {
  width: 170px;
}
</style>
